import { template as template_26718213bebe4ef49e5ab1425a1f840a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_26718213bebe4ef49e5ab1425a1f840a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
