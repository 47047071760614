import { template as template_d2faf14885ed41f9b6fd9704bf603452 } from "@ember/template-compiler";
const WelcomeHeader = template_d2faf14885ed41f9b6fd9704bf603452(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
