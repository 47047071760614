import { template as template_dd1d5b023c0a4a3b8af95934d0a5f6b7 } from "@ember/template-compiler";
const FKText = template_dd1d5b023c0a4a3b8af95934d0a5f6b7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
